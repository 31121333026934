<template>
  <v-app>
    <v-app-bar app inverted-scroll>
      <div class="d-flex align-center">
        <router-link to="/">
        <v-img
          alt="Klaru Logo"
          class="shrink mr-2"
          contain
          :src="require('@/assets/logo.png')"
          transition="scale-transition"
          width="148"
        />
        </router-link>
      </div>

      <v-spacer></v-spacer>

      <v-btn
        @click="$vuetify.goTo(0)"
        color="primary"
      >
        <v-icon>mdi-download</v-icon>
        <span class="mr-2">Try Klaru Today</span>
      </v-btn>
    </v-app-bar>

    <v-main id="main">
      <!-- <Home/> -->
      <router-view />
    </v-main>

    <FooterComponent />
  </v-app>
</template>

<script>
//import Home from './screen/Home';
import FooterComponent from './components/Footer';

export default {
  name: 'App',

  components: {
    //Home,
    FooterComponent
  },

  data: () => ({ }),
};
</script>
<style lang="scss" scoped>
  @media screen and (max-width: 600px) {
    :deep(.v-toolbar__content) {
      max-width: 88%;
    }
  }
</style>
