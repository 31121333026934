<template>
  <v-footer>
    <v-col cols="12">
      <v-divider />
    </v-col>
    
    <v-col cols="6" sm="12" class="text-center pt-4 mb-0">
      &#169;&nbsp;2022 Klaru, Inc.
    </v-col>
    
      <!-- :class="{ 'px-0': $vuetify.breakpoint.mobile }" -->
    <div class="d-flex snsitems">
      <a
        v-for="(item, index) in icons"
        :key="`sns-${index}`"
        class="px-0 mx-1"
        :href="item.link"
        target="_blank"
        text
        x-small
        depressed
      >
        <v-icon color="teal">{{ item.name }}</v-icon>
      </a>
    </div>
    <v-col cols="12" class="d-flex justify-center">
      <router-link to="/privacy">Privacy Policy</router-link>
      <router-link to="/terms">Terms & Conditions</router-link>
    </v-col>
  </v-footer>
</template>

<script>
  export default {
    name: 'App',
  
    data: () => ({
      icons: [
        {
          name: "mdi-twitter",
          link: "",
        },
        {
          name: "mdi-facebook",
          link: "",
        },
        {
          name: "mdi-instagram",
          link: "",
        },
        {
          name: "mdi-linkedin",
          link: "",
        },
        {
          name: "mdi-youtube",
          link: "",
        },
      ],
    }),
    methods: {
      openUrl(url) {
        window.open(url);
      },
    }
  };
  </script>
  <style scoped lang="scss">
    .v-footer {
      position: relative;
      background-color: white;
      a {
        color: black;
        text-decoration: none;
        margin: 0 8px;
        font-weight: bold;
        &:hover {
          opacity: .6;
        }
      }
      .snsitems {
        position: absolute;
        right: 24px;
        top: 40px;
      }
    }
  </style>
  