import Vue from 'vue'
import VueRouter from 'vue-router'

import Main from "@/layout/Main.vue";

Vue.use(VueRouter)

const routes = [
  { 
    path: '/', 
    component: Main,
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("@/screen/Home.vue"),
      },
      {
        path: "/privacy",
        name: "privacy",
        component: () => import("@/screen/Privacy.vue"),
      },
      {
        path: "/terms",
        name: "terms",
        component: () => import("@/screen/Terms.vue"),
      },
    ],
  },
]

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;