<template>
  <v-main>
    <router-view />
  </v-main>
</template>
<script>
export default {
  name: "MainLayout",
  data() {
    return {

    }
  },
};
</script>
<style scoped lang="scss">

</style>